export const ENV = 'stage'

export const HOST_URL = ENV === 'production' ? 'https://api.aentry.app' : 'https://api.stg.aentry.app';

export const FRONTEND_URL = ENV === "development" || ENV === "stage"
    ? "https://stg.aentry.app"
    : "https://aentry.app";

export const BASE = HOST_URL + '/app';

export const API_USER_LOGIN = BASE + '/customer/login';
export const API_VERIFY_LOGIN = BASE + '/customer/login-verified';
export const API_SIGN_UP = BASE + '/customer/add';
export const API_EVENTS = BASE + '/venue-event/event-list';
export const API_EVENT_DETAILS = BASE + '/venue-event/event-detail';
export const API_EVENT_TICKET_DETAILS = BASE + '/event-ticket/ticket-details';
export const API_TICKET_VOUCHER_CODE_VERIFY = BASE + '/event_voucher/voucher-code-verify';
export const API_VERIFY_PROMO_CODE = BASE + '/customer/promo_code_input';
export const API_SUBSCRIPTION_PLANS = BASE + '/customer-subscription/subscription-list';
export const API_ADD_CUSTOMER_SUBSCRIPTION = BASE + '/customer/subscription/add';
export const API_ADD_PAYMENT_METHOD = BASE + '/customer/payment/add';
export const API_TICKET_PURCHASE = BASE + '/event-ticket/ticket-purchase';
export const API_CUSTOMER_DETAILS = BASE + '/customer/customer-details';
export const API_CUSTOMER_SUBSCRIPTION_DETAILS = BASE + '/customer/subscription-details';
export const API_CANCEL_CUSTOMER_SUBSCRIPTION = BASE + '/customer/subscription/cancel';
export const API_PAYMENT_METHODS = BASE + '/customer/payment-methods';
export const API_DELETE_ACCOUNT = BASE + '/customer/delete-customer';
export const API_SAVE_MOBILE_NUMBER = BASE + '/customer/mobile-number';
export const API_COUNTRY = BASE + '/country';
export const API_CUSTOMER_PAYMENT_SUBSCRIPTION = BASE + '/customer/customer-payment-subscription';
export const API_ADD_CUSTOMER_PAYMENT_METHOD_SUBSCRIPTION = BASE + '/customer/customer-payment-freesubscription';
export const API_CLIENT_SECRET_KEY = BASE + '/customer/subscription/create-secret-key'




